<template>
    <div class="machineTime">
        <el-form inline :model="selectModel" ref="searchForm" label-position="right">
            <el-form-item label="设备ID">
            <el-input v-model="selectModel.machine_id" />
            </el-form-item>
            <el-form-item label="设备编号">
            <el-input v-model="selectModel.no" />
            </el-form-item>
            <el-form-item label="设备IMEI">
            <el-input v-model="selectModel.imei" />
            </el-form-item>
            <el-form-item label="类型">
            <el-select v-model="selectModel.type_id" placeholder="请选择" clearable>
                <el-option v-for="item in machineTypes" :key="item.id" :value="item.id" :label="item.val" />
            </el-select>
            </el-form-item>
            <el-form-item label="学校">
            <el-select v-model="selectModel.college_id" placeholder="请选择" clearable>
                <el-option v-for="item in colleges" :key="item.id" :value="item.id" :label="item.val" />
            </el-select>
            </el-form-item>
            <el-form-item label="是否设置">
            <el-select v-model="selectModel.is_set" placeholder="请选择" clearable>
                <el-option :value="1" label="是" />
                <el-option :value="0" label="否" />
            </el-select>
            </el-form-item>
            <el-form-item class="main-form-item-float-right">
            <el-button-group>
                <el-button type="primary" icon="el-icon-search" @click="getListData">查询</el-button>
            </el-button-group>
            <el-button-group>
                <el-button type="primary"  @click="resetForm">重置
                </el-button>
            </el-button-group>
             <el-button-group>
                <el-button type="primary" @click="handleTimeSetting">设置工作时间范围
                </el-button>
            </el-button-group>
            </el-form-item>
        </el-form>

        <el-table :data="tableData" ref="machineTable"  @selection-change="handleSelectionChange" border stripe  style="width: 100%;">
            <el-table-column type="selection" width="55" align="center"/>
            <el-table-column label="设备ID" prop="machineId" width="100px" align="center" />
            <el-table-column label="设备编号" prop="no" min-width="100px">
            <template #default="scope">
                <span class="main-column-no" @click="showQRCode(scope.row.no)">{{ scope.row.no }}</span>
            </template>
            </el-table-column>
            <el-table-column label="设备IMEI" prop="imei" min-width="160px" />
            <el-table-column label="类型" prop="type" min-width="160px" />
            <el-table-column label="状态" prop="status" min-width="80px" />
            <el-table-column label="所属学校" prop="collegeName" min-width="200px" />
            <el-table-column label="设置工作时间范围"  min-width="180px" >
                <template #default="scope">
                   <span v-if="scope.row.isAllday=='1'">全天</span>
                   <span v-else>{{scope.row.startTime }}-- {{scope.row.endTime }}</span>
                </template>
            </el-table-column>
            <el-table-column label="判断机器是否可用"  align="center">
                <template #default="scope">
                    <el-button type="text" @click="handleTesting(scope.row.machineId)">验证</el-button>
                </template>
            </el-table-column>
            
        </el-table>
        <div style="margin-top: 10px; text-align: right;">
            <el-pagination layout="prev, pager, next,jumper" :total="total" @current-change="handleCurrentPage" />
        </div>
        <el-dialog custom-class="main-el-dialog" :title="dialog.title" v-model="dialog.visibled" width="600px">
            <div style="margin:10px"><el-switch v-model="dialog.form.isAllday"  active-text="全天"></el-switch></div>
              <el-time-picker
              v-if="!dialog.form.isAllday"
                is-range
                style="margin:10px"
                v-model="dialog.form.date"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                format="HH:mm"
                value-format='HH:mm'
                placeholder="选择时间范围">
            </el-time-picker>
            <template #footer>
            <span class="dialog-footer">
                <el-button @click="onCancel">取消</el-button>
                <el-button type="primary" @click="handleSaveTime">保存</el-button>
            </span>
            </template>
        </el-dialog>
    </div>

</template>

<script>
import { handleCatchStatus } from "../../api/handler";

export default {
  data() {
    return {
      selectModel: {},
      selectSort: null,
      tableData: [],
      total: 0,
      current: 1,
      // 选中行
      selections: [],
      machineTypes: [],
      colleges: [],
      dialog: {
        visibled: false,
        title: '设置工作时间范围',
        form: {
            date:'',
            isAllday:false
        }
      },
    };
  },
  created() {
    this.$api.getCollegeMap().then(data => {
      this.colleges = data;
    });
    this.$api.getMachineTypeMap().then(data => {
      this.machineTypes = data;
    });
    this.getListData();
  },
  mounted() {},
  methods: {
    getListData() {
      this.selectModel.page = this.current;
      this.$api
        .getMachineTimeList(this.selectModel)
        .then(data => {
          this.tableData = data.list;
          this.total = data.total;
        })
        .catch(handleCatchStatus);
    },
    handleTesting(no){
        this.$api
        .getMachineStatus(no)
        .then(data => {
            if(data.content>=0){
                this.$message.warning('设备可运行!');
            }else{
                this.$message.warning('设备不可运行!');
            }
        })
        .catch(handleCatchStatus);
    },
    handleTimeSetting(){
        if(this.selections.length===0){
           return this.$message.warning('请选择设备!');
        }
        this.dialog.visibled=true
    },
    handleSaveTime(){
        let machineData=[]
        if(this.dialog.form.isAllday){
            this.selections.map(item=>{
                machineData.push({
                    is_allday:1,
                    machine_id:item.machineId,
                    start_time:'',
                    end_time:''
                })
            })
            this.$api
            .setMachineTime(machineData)
            .then(data => {
                this.onCancel()
                this.selections=[]
                this.$refs.machineTable.clearSelection()
                this.getListData()
            })
            .catch(handleCatchStatus);
        }else if(this.dialog.form.date.length>0){
            this.selections.map(item=>{
                machineData.push({
                    is_allday:0,
                    machine_id:item.machineId,
                    start_time:this.dialog.form.date[0],
                    end_time:this.dialog.form.date[1]
                })
            })
            this.$api
            .setMachineTime(machineData)
            .then(data => {
                this.selections=[]
                this.$refs.machineTable.clearSelection()
                this.onCancel()
                this.getListData()
            })
            .catch(handleCatchStatus);
           
        }else{
            return this.$message.warning('请选择时间')
        }
    },
    handleSelectionChange(selection) {
        this.selections = selection;
        console.log(this.selections,1111)
    },
    resetForm() {
      this.$refs.searchForm.resetFields();
      this.selectModel={}
      this.getListData()
    },
    onCancel(){
        this.dialog.visibled=false
        this.dialog.form.date='',
        this.dialog.formisAllday=false
    },
    handleCurrentPage(current) {
       this.current=current
      this.getListData();
    }
  }
};
</script>

<style lang="scss" scoped>
.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 100%;
}

.qr-no {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.qr-img {
  width: 100%;
}
</style>
